/* global fbq process */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux-v5';
import {
    clearFiles,
    getFiles,
    getNewFiles,
    pickFiles,
    updateCategories,
    uploadFiles,
    updateVisibleTo,
} from '../../actions/upload';
import { isArray } from 'lodash';
import { ProgressBar } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import AbbreviatedMultiSelect from '../pure/form/inputs/AbbreviatedMultiSelect';
import FileDropzone from 'components/pure/FileDropzone';
import LabeledDropdown from './form/inputs/LabeledDropdown';
import segmentEvents from '../../helpers/segmentEvents';
import { UploadContext } from '../context/UploadContext';

export class PureUploadFile extends Component {
    constructor(props) {
        super(props);
        this.uploadBtn = {};
    }

    componentWillMount() {
        this.props.clearFiles();
    }

    componentDidUpdate(prevProps) {
        const {
            newFiles,
            uploadFiles,
            type = 'factoring',
            baseId,
            triggerUploadEvent,
        } = this.props;
        if (prevProps.newFiles !== newFiles) {
            if (type === 'payment') {
                window.analytics.track(
                    segmentEvents.BROKER_UPLOADED_DOCUMENTS_CARRIER,
                );
            }
            if (type === 'factoring') {
                window.analytics.track(
                    segmentEvents.USER_UPLOAD_NEW_DOCUMENTS_FUNDING_REQUEST,
                );
            }
            uploadFiles(newFiles, type, baseId);
            triggerUploadEvent();
        }
    }

    VisibilityMapping = (vis) => {
        if (Array.isArray(vis)) {
            if (
                (vis.includes('shipper') || vis.includes('debtor')) &&
                vis.includes('carrier')
            ) {
                return 'Carrier & Shipper/Debtor';
            }

            if (vis.includes('shipper') || vis.includes('debtor')) {
                return 'Shipper/Debtor Only';
            }

            if (vis.includes('carrier')) {
                return 'Carrier Only';
            }
        }
    };

    categoriesHandler = async (categories, i) => {
        const { updateCategories: updateCategoriesProps, type = 'factoring' } =
            this.props;
        if (isArray(categories)) {
            await updateCategoriesProps({
                position: i,
                categories: categories.join(','),
                type,
            });
        } else {
            await updateCategoriesProps({
                position: i,
                categories,
                type,
            });
        }
    };

    visibleToHandler = async (visibleTo, i) => {
        const { updateVisibleTo: updateVisibleToProps } = this.props;
        await updateVisibleToProps({
            position: i,
            visible_to: visibleTo,
        });
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
        const { pickFiles, requestId, setCropValues } = this.props;
        if (acceptedFiles) {
            if (setCropValues && /image\//.test(acceptedFiles[0].type)) {
                return setCropValues({
                    cropping: acceptedFiles[0],
                    onCrop: (newValue) => {
                        let image = newValue;
                        if (
                            newValue instanceof Blob &&
                            !(newValue instanceof File)
                        ) {
                            image = new File([newValue], newValue.name);
                        }
                        setCropValues({ cropping: false });
                        setTimeout(() => pickFiles(image, requestId), 500);
                    },
                    onCropCancel: () => {
                        setCropValues({
                            cropping: false,
                        });
                        setTimeout(
                            () => pickFiles(acceptedFiles[0], requestId),
                            500,
                        );
                    },
                });
            }
            return pickFiles(acceptedFiles[0], requestId);
        }
    };

    render() {
        const {
            pickFiles,
            requestId,
            files = [],
            categories,
            categoriesProps = [],
            multiple,
            attachments = [],
            dropZone,
            setCropValues,
            extraText = '',
            visibility,
            canUploadNew = true,
            canViewFiles = false,
        } = this.props;

        return (
            <div>
                {canUploadNew ? (
                    dropZone && !files.length ? (
                        <FileDropzone onDrop={this.onDrop} accept="image/*" />
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {extraText && <div>{extraText}</div>}
                            <div>
                                <button
                                    className="btn btn-orange"
                                    style={{
                                        backgroundColor: '#2196f3',
                                        fontSize: '14px',
                                        color: 'white',
                                        border: 0,
                                    }}
                                    onClick={() => this.uploadBtn.click()}
                                    type="button"
                                >
                                    Upload Documents{' '}
                                    <MaterialIcon
                                        className="mdi-24px"
                                        name="backup"
                                    />
                                </button>
                                <input
                                    type="file"
                                    ref={(ref) => (this.uploadBtn = ref)}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        if (
                                            setCropValues &&
                                            /image\//.test(
                                                e.target.files[0].type,
                                            )
                                        ) {
                                            return setCropValues({
                                                cropping: e.target.files[0],
                                                onCrop: (newValue) => {
                                                    let image = newValue;
                                                    if (
                                                        newValue instanceof
                                                            Blob &&
                                                        !(
                                                            newValue instanceof
                                                            File
                                                        )
                                                    ) {
                                                        image = new File(
                                                            [newValue],
                                                            newValue.name,
                                                        );
                                                    }
                                                    setCropValues({
                                                        cropping: false,
                                                    });
                                                    setTimeout(
                                                        () =>
                                                            pickFiles(
                                                                image,
                                                                requestId,
                                                            ),
                                                        500,
                                                    );
                                                    e.currentTarget.value = '';
                                                },
                                                onCropCancel: () => {
                                                    setCropValues({
                                                        cropping: false,
                                                    });
                                                    setTimeout(
                                                        () =>
                                                            pickFiles(
                                                                e.target
                                                                    .files[0],
                                                                requestId,
                                                            ),
                                                        500,
                                                    );
                                                    e.currentTarget.value = '';
                                                },
                                            });
                                        }
                                        pickFiles(e.target.files[0], requestId);
                                        e.currentTarget.value = '';
                                    }}
                                />
                            </div>
                        </div>
                    )
                ) : null}
                {(files.length > 0 || attachments.length > 0) && (
                    <div style={{ padding: 10 }}>
                        <table
                            className="table"
                            style={{ fontSize: 'smaller' }}
                        >
                            <thead>
                                <tr>
                                    <th colSpan="5">File Name</th>
                                    <th colSpan="5">Category</th>
                                    {visibility && (
                                        <th colSpan="5">Visible To</th>
                                    )}
                                    {canViewFiles && <th colSpan="5">View</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {attachments.map((file, i) => (
                                    <tr>
                                        <td colSpan="5">{file.filename}</td>
                                        <td colSpan="5">{file.category}</td>
                                        {canViewFiles && (
                                            <td colSpan="5">
                                                <button
                                                    style={{
                                                        backgroundColor:
                                                            '#26a69a',
                                                        color: 'white',
                                                    }}
                                                    className="btn"
                                                    type="button"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        try {
                                                            window.open(
                                                                file.download_url,
                                                            );
                                                        } catch (err) {
                                                            console.warn(err);
                                                        }
                                                    }}
                                                >
                                                    <MaterialIcon
                                                        name="visibility"
                                                        size={16}
                                                    />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {files.map((file, i) => (
                                    <tr>
                                        <td colSpan="5">
                                            <p
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    width: 100,
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {file.name}
                                            </p>
                                            {file.status !== 'success' && (
                                                <ProgressBar
                                                    now={file.progress}
                                                />
                                            )}
                                            {file.progress === 100 &&
                                                file.status === 'success' && (
                                                    <p className="text-success">
                                                        Upload successful!
                                                    </p>
                                                )}
                                        </td>
                                        <td colSpan="5">
                                            {file.status === 'success' &&
                                            multiple ? (
                                                <AbbreviatedMultiSelect
                                                    {...categoriesProps}
                                                    placeholder="categories"
                                                    style={{
                                                        display: 'flex',
                                                        minHeight: 30,
                                                        width: 250,
                                                        textOverflow:
                                                            'ellipsis',
                                                        alignItems: 'center',
                                                        fontSize: 12,
                                                    }}
                                                    onChange={async (
                                                        category,
                                                        ...args
                                                    ) =>
                                                        await this.categoriesHandler(
                                                            category,
                                                            i,
                                                        )
                                                    }
                                                    disabled={
                                                        files[i].status !==
                                                        'success'
                                                    }
                                                    data={categories}
                                                    value={
                                                        file.hasOwnProperty(
                                                            'categories',
                                                        ) && file.categories
                                                            ? (
                                                                  file.categories ||
                                                                  []
                                                              ).split(',')
                                                            : []
                                                    }
                                                />
                                            ) : (
                                                <LabeledDropdown
                                                    input={{
                                                        onChange: async (
                                                            category,
                                                        ) => {
                                                            await this.categoriesHandler(
                                                                category,
                                                                i,
                                                            );
                                                        },
                                                        value: file.categories,
                                                    }}
                                                    disabled={
                                                        files[i].status !==
                                                        'success'
                                                    }
                                                    data={categories}
                                                    placeholder="Category"
                                                    style={{
                                                        margin: '0 0.5em',
                                                    }}
                                                    containerProps={{
                                                        style: {
                                                            marginBottom: 0,
                                                        },
                                                    }}
                                                />
                                            )}
                                        </td>
                                        {visibility && (
                                            <td>
                                                <LabeledDropdown
                                                    input={{
                                                        onChange: async (
                                                            visibility,
                                                        ) => {
                                                            if (
                                                                visibility ===
                                                                'Carrier Only'
                                                            ) {
                                                                file.visible_to =
                                                                    ['carrier'];
                                                            } else if (
                                                                visibility ===
                                                                'Shipper/Debtor Only'
                                                            ) {
                                                                file.visible_to =
                                                                    [
                                                                        'shipper',
                                                                        'debtor',
                                                                    ];
                                                            } else if (
                                                                visibility ===
                                                                'Carrier & Shipper/Debtor'
                                                            ) {
                                                                file.visible_to =
                                                                    [
                                                                        'carrier',
                                                                        'shipper',
                                                                        'debtor',
                                                                    ];
                                                            }
                                                            await this.visibleToHandler(
                                                                file.visible_to,
                                                                i,
                                                            );
                                                        },
                                                        value: this.VisibilityMapping(
                                                            file.visible_to,
                                                        ),
                                                    }}
                                                    data={[
                                                        'Carrier Only',
                                                        'Shipper/Debtor Only',
                                                        'Carrier & Shipper/Debtor',
                                                    ]}
                                                    disabled={
                                                        files[i].status !==
                                                        'success'
                                                    }
                                                    placeholder="Visible To"
                                                    style={{
                                                        margin: '0 0.5em',
                                                    }}
                                                    containerProps={{
                                                        style: {
                                                            marginBottom: 0,
                                                        },
                                                    }}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

PureUploadFile.defaultProps = {
    single: false,
    categories: [
        'Signed Bill of Lading (Delivered)',
        'Signed Bill of Lading (Pick-Up)',
        'Rate Confirmation',
        'Truck Order Not Used',
        'Invoice',
        'Freight Bill',
        'Fuel Advance',
        'Lumper Receipt',
        'Detention Receipt',
        'Late Fee Receipt',
        'Other',
    ],
    files: [],
    isClearFiles: false,
    multiple: false,
    buttonClearText: 'Clear Documents',
    categoriesProps: {},
    visibility: true,
};

PureUploadFile.propTypes = {
    files: PropTypes.array.isRequired,
    requestId: PropTypes.string.isRequired,
    categories: PropTypes.array,
    categoriesProps: PropTypes.array,
    single: PropTypes.bool,
    isClearFiles: PropTypes.bool,
    multiple: PropTypes.bool,
    noCategories: PropTypes.bool,
    buttonClearText: PropTypes.string,
    clearFilesButtonAction: PropTypes.func,
    attachments: PropTypes.array,
    visibility: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
    files: getFiles(state, props.type),
    newFiles: getNewFiles(state, props.type),
});

const mapDispatchToProps = {
    pickFiles,
    uploadFiles,
    updateCategories,
    clearFiles,
    updateVisibleTo,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})((props) => (
    <UploadContext.Consumer>
        {({ triggerUploadEvent }) => (
            <PureUploadFile
                {...props}
                triggerUploadEvent={triggerUploadEvent}
            />
        )}
    </UploadContext.Consumer>
));
