/**
 * Container component for routes viewed while logged-out.
 * @module components/pure/LoggedOutContainer
 * @since 3.0.0
 * @requires styles/LoggedOutContainer
 * @property {object} props
 * @property {(node|node[])} [props.children] - child(ren) node(s) to render
 */
import 'styles/LoggedOutContainer';
import React from 'react';
import PropTypes from 'prop-types';

export const LoggedOutContainer = ({ children }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const alreadyLoggedIn = urlParams.get("as");
    const loggingInAsUser = urlParams.get("loggingInAsUser");

    if (alreadyLoggedIn) {
        if (loggingInAsUser) {
            window.location.href = "/settings/account";
        }
        else {
            window.location.href = "/";
        }
    }

    return (
        <div className="LoggedOutContainer">
            <div className="LoggedOutContainer__container">{children}</div>
        </div>
    );
};

LoggedOutContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};

export default LoggedOutContainer;
