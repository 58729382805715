import React from 'react';
import { IndexRedirect, IndexRoute, Redirect, Route } from 'react-router-v4';
import requireSelectors from './js/components/hoc/requireSelectors';
import App from './js/components/container/App';
import Index from './js/components/container/Index';

import LoggedOutContainer from './js/components/pure/LoggedOutContainer';
import Login from './js/components/pure/form/LoginForm';
import Signup from './js/components/pure/form/SignupForm';
import ResetPassword from './js/components/pure/form/ResetPasswordForm';
import ResetPasswordConfirm from './js/components/pure/form/ResetPasswordConfirmForm';

import User from './js/components/container/User';
import UserSearch from './js/components/container/UserSearch';
import UserEdit from './js/components/container/UserEdit';
import UserTypeList from './js/components/container/UserTypeList';
import MyUserTypeList from './js/components/container/MyUserTypeList';
import MyUserHaulPayInterestedList from './js/components/container/MyUserHaulPayInterestedList';

import TruckDetails from './js/components/container/TruckDetails';
import TruckSearch from './js/components/container/TruckSearch';
import TruckPost, {
    TruckPostResults,
} from './js/components/container/TruckPost';
import TruckPostHistory from './js/components/container/TruckPostHistory';
import TruckLoadMatches from './js/components/container/TruckLoadMatches';

import LoadDetails from './js/components/container/LoadDetails';
import LoadSearch from './js/components/container/LoadSearch';
import LoadPost, { LoadPostResults } from './js/components/container/LoadPost';
import LoadPostContainer from './js/components/container/LoadPostContainer';
import LoadPostHistory from './js/components/container/LoadPostHistory';

import LoadMaps from './js/components/container/LoadMaps';

import RateIndex from './js/components/pure/RateIndex';

import Settings from './js/components/container/Settings';
import SettingsNotifications from './js/components/container/SettingsNotifications';
import SettingsAccount from './js/components/container/SettingsAccount';

import SettingsProfileMain from './js/components/container/SettingsProfile';
import SettingsProfile from './js/components/pure/SettingsProfile';
import SettingsProfileAccounting from './js/components/container/SettingsProfileAccounting';

import SettingsBilling from './js/components/container/SettingsBilling';
import SettingsBillingSubscription from './js/components/container/SettingsBillingSubscription';
import SettingsBillingDispatch from './js/components/container/SettingsBillingDispatch';

import SettingsDocuments from './js/components/pure/SettingsDocuments';

import Factoring from './js/components/container/Factoring';
import FactoringSignUp from './js/components/container/FactoringSignUp';
import HaulPayMarketingNoMenu from './js/components/container/HaulPayMarketingNoMenu';

import FactoringGetPaid from './js/components/container/FactoringGetPaid';
import FactoringRequestPayment from './js/components/container/FactoringRequestPayment';
import FactoringCreditCheck from './js/components/container/FactoringCreditCheck';

import FactoringCarriers from './js/components/container/FactoringCarriers';
import FactoringCarriersMyCarriers from './js/components/container/FactoringCarriersMyCarriers';
import FactoringCarriersInvited from './js/components/container/FactoringCarriersInvited';
import FactoringAddClients from './js/components/container/FactoringAddClients';

import FactoringPayments from './js/components/container/FactoringPayments';
import FactoringIncompletePayments from './js/components/container/FactoringPayments/FactoringIncompletePayments';
import FactoringPayment from './js/components/container/FactoringPayment';
import AssignedLoadPaymentView from './js/components/container/AssignedLoads/ViewAssignedPaymentRequest';
import AssignedLoads from './js/components/container/AssignedLoads';
import FactoringARProfile from './js/components/container/FactoringARProfile';
import FactoringUserProfile from './js/components/container/FactoringUserProfile';

import FactoringLeads from './js/components/container/FactoringLeads';
import FactoringClientLists from './js/components/container/FactoringClientLists';
import FactoringBrokerInvitedLists from './js/components/container/FactoringBrokerInvitedLists';
import FactoringClientListInactive from './js/components/container/FactoringClientListInactive';
import FactoringClient from './js/components/container/FactoringClient';
import FactoringClientPayments from './js/components/container/FactoringClientPayments';
import FactoringClientDebtors from './js/components/container/FactoringClientDebtors';
import FactoringClientDebtorSearch from './js/components/container/FactoringClientDebtorSearch';
import FactoringClientEdit from './js/components/container/FactoringClientEdit';
import FactoringClientSearch from './js/components/container/FactoringClientSearch';
import FactoringClientPaymentCreateAdmin from './js/components/container/FactoringClientPaymentCreateAdmin';
import FactoringClientBrokersCarriers from './js/components/container/FactoringClientBrokersCarriers';
import FactoringDebtorLists from './js/components/container/FactoringDebtorLists';
import FactoringDebtor from './js/components/container/FactoringDebtor';
import FactoringDebtorPayments from './js/components/container/FactoringDebtorPayments';
import FactoringInvoicesPayments from './js/components/container/FactoringInvoicesPayments';
import FactoringDebtorClients from './js/components/container/FactoringDebtorClients';
import FactoringDebtorCreate from './js/components/container/FactoringDebtorCreate';
import FactoringDebtorEdit from './js/components/container/FactoringDebtorEdit';
import FactoringDebtorSearch from './js/components/container/FactoringDebtorSearch';
import FactoringLineItemCreate from './js/components/container/FactoringLineItemcreate';
import FactoringPaymentsAdmin from './js/components/container/FactoringPaymentsAdmin';
import FactoringPaymentsAdminEdit from './js/components/container/FactoringPaymentsAdminEdit';
import FactoringPaymentsSearch from './js/components/container/FactoringPaymentsSearch';

import AdminDashboard from './js/components/container/AdminDashboard';
import AdminDashboardStats2 from './js/components/container/AdminDashboardStats2';
import AdminDashboardClients from './js/components/container/AdminDashboardClients';
import AdminDashboardFinance from './js/components/container/AdminDashboardFinance';

import Dispatch from './js/components/container/Dispatch';
import DispatchSearch from './js/components/container/DispatchSearch';
import DispatchList from './js/components/container/DispatchList';
import DispatchUpdateCard from './js/components/container/DispatchUpdateCard';
import DispatchOnboarding from './js/components/container/DispatchOnboarding';

import FinancesPayments from './js/components/container/FinancesPayments';
import FinancesPaymentsDebtorSearch from './js/components/container/FinancesPaymentsDebtorSearch';
import FinancesPurchases from './js/components/container/FinancesPurchases';
import FinancesPaymentEdit from './js/components/container/FinancesPaymentEdit';
import FinancesPurchaseEdit from './js/components/container/FinancesPurchaseEdit';
import LineItemCategories from './js/components/container/LineItemCategories';
import BrokerWallet from './js/components/container/BrokerWallet';
import FRBulkImport from './js/components/container/FRBulkImport';
import MyCards from './js/components/container/MyCards';
import StripeSuccessOnboarding from './js/components/container/StripeSuccessOnboarding';

import SavedPosts from './js/components/container/SavedPosts';
import SavedLoads from './js/components/container/SavedLoads';
import SavedTrucks from './js/components/container/SavedTrucks';

import Help from './js/components/pure/Help';
import Error404 from './js/components/pure/Error';

import store from './js/store';
import { login, logout } from './js/actions/user';
import isValidState from './js/helpers/isValidState';

import isAdmin from './js/selectors/isAdmin';
import isSubAdmin from './js/selectors/isSubAdmin';
import isFactoringAdmin from './js/selectors/isFactoringAdmin';
import isFactoringSubAdmin from './js/selectors/isFactoringSubAdmin';
import isFactoringRemoteAdmin from './js/selectors/isFactoringRemoteAdmin';
import isFactoringRemoteCoordinator from './js/selectors/isFactoringRemoteCoordinator';
import isAladdinAdmin from './js/selectors/isAladdinAdmin';

import isBroker from './js/selectors/isBroker';
import isCarrier from './js/selectors/isCarrier';
import isShipper from './js/selectors/isShipper';
import isDispatcher from './js/selectors/isDispatcher';
import isDispatchBroker from './js/selectors/isDispatchBroker';

import hasNoUserType from './js/selectors/hasNoUserType';
import DebtorTransactionContainer from './js/components/container/DebtorTransactionContainer';
import FactoringPaymentEditBroker from './js/components/container/FactoringPayments/FactoringBrokerPayments/FactoringPaymentEditBroker';
import LoadsContainer from './js/components/container/LoadsContainer';
import MyCarriersBrokers from './js/components/container/MyCarriersBrokers';
import BrokerTransaction from './js/components/container/BrokerTransaction';
import BrokerInvitedCarriers from './js/components/container/BrokerInvitedCarriers';
import APISettings from './js/components/container/APISettings';
import CarrierBulkUpload from './js/components/container/CarrierBulkUpload';
import withUploadProvider from './js/components/context/withUploadProvider';

const requireAdmin = requireSelectors(
    [
        isAdmin,
        isSubAdmin,
        isFactoringAdmin,
        isFactoringSubAdmin,
        isFactoringRemoteAdmin,
        isFactoringRemoteCoordinator,
        isAladdinAdmin,
    ],
    '/404',
);

const requireUser = requireSelectors([
    isBroker,
    isShipper,
    isCarrier,
    isDispatcher,
]);

const requireTrucker = requireSelectors([isBroker, isCarrier, isDispatcher]);

const requireCargoer = requireSelectors([isBroker, isShipper, isDispatcher]);

const requireBroker = requireSelectors([isBroker]);

const transactionType = [
    <Route path="pending" />,
    <Route path="non_factored" />,
    <Route path="fuel_advance" />,
    <Route path="document_issue" />,
    <Route path="incomplete" />,
    <Route path="remote_approved" />,
    <Route path="approved" />,
    <Route path="declined" />,
    <Route path="carrier_paid" />,
    <Route path="invoice_paid" />,
    <Route path="hold" />,
    <Route path="paid" />,
    <Route path="search" />,
    <Route path="new" />,
    <IndexRedirect to="pending" />,
];

/**
 * The routes for the app
 */
const routes = (
    <Route
        path="/"
        component={App}
        onEnter={() => {
            const state = store.getState();
            if (!state.user.logged_in) {
                store.dispatch(login());
            }
        }}
    >
        <Route
            path="logout"
            onEnter={(nextState, replace) => {
                store.dispatch(logout());
                window.location.replace('/login');
            }}
        />
        <Route
            component={LoggedOutContainer}
            onEnter={(nextState, replace) => {
                const state = store.getState();
                if (state.user.logged_in) {
                    replace('/');
                }
            }}
        >
            <Route path="login" component={Login} />
            <Route path="signup" component={Signup} />
            <Route path="resetpassword" component={ResetPassword} />
            <Route
                path="resetpasswordconfirm"
                component={ResetPasswordConfirm}
            />
        </Route>

        <IndexRoute
            component={requireSelectors([
                isAdmin,
                isFactoringRemoteAdmin,
                isFactoringRemoteCoordinator,
                isAladdinAdmin,
                isBroker,
                isCarrier,
                isShipper,
                isDispatcher,
            ])(Index)}
        />
        <Route path="truck/:id" component={TruckDetails} />
        <Route
            path="trucksearch"
            component={requireSelectors([
                isAdmin,
                isSubAdmin,
                isBroker,
                isShipper,
                isDispatcher,
            ])(TruckSearch)}
        >
            <Route path="filter" />
        </Route>
        <Route path="posttruck" component={requireTrucker(TruckPost)} />
        <Route
            path="posttruck/history"
            component={requireTrucker(TruckPostHistory)}
        />
        <Route
            path="truck/:id/matches"
            component={requireTrucker(TruckLoadMatches)}
        />

        <Route path="load/:id" component={LoadDetails} />
        <Route
            path="loadsearch"
            component={requireSelectors([
                isAdmin,
                isSubAdmin,
                isBroker,
                isCarrier,
                isDispatcher,
            ])(LoadSearch)}
        >
            <Route path="filter" />
        </Route>
        <Route path="postload" component={requireCargoer(LoadPostContainer)}>
            <Route
                path="all-loads"
                component={requireCargoer(LoadsContainer)}
            />
            <Route
                path="haulpay"
                component={requireCargoer(Factoring(LoadsContainer))}
            />
            <IndexRedirect to="all-loads" />
        </Route>
        <Route
            path="postload/history"
            component={requireCargoer(LoadPostHistory)}
        />

        <Route path="user">
            <Route path=":id">
                <IndexRoute component={requireUser(User)} />
                <Route path="load" component={requireUser(LoadPostResults)} />
                <Route path="truck" component={requireUser(TruckPostResults)} />
            </Route>
        </Route>

        <Route
            path="loadmaps(/:state(/:city))"
            component={requireSelectors([isCarrier])(LoadMaps)}
            onEnter={(nextState, replace) => {
                const {
                    params: { state },
                } = nextState;
                if (state && !isValidState.url(state)) {
                    return replace('/loadmaps');
                }
            }}
        />

        <Redirect from="rateindex" to="/ratetrend" />
        <Route path="ratetrend" component={requireUser(RateIndex)} />

        <Route path="settings" component={requireUser(Settings)}>
            <Route path="notifications" component={SettingsNotifications} />
            <Route path="account" component={SettingsAccount} />
            <Route path="profile" component={SettingsProfileMain}>
                <Route path="user" component={SettingsProfile} />
                <Route
                    path="accounting"
                    component={SettingsProfileAccounting}
                />
                <IndexRedirect to="user" />
            </Route>
            <Route path="billing" component={SettingsBilling}>
                <Route
                    path="subscription"
                    component={SettingsBillingSubscription}
                />
                <Route path="dispatch" component={SettingsBillingDispatch} />
                <IndexRedirect to="subscription" />
            </Route>
            <Route path="documents" component={SettingsDocuments} />
            <IndexRedirect to="notifications" />
            <Route
                path="API"
                component={requireSelectors([isBroker])(APISettings)}
            />
        </Route>

        <Route path="help" component={requireUser(Help)} />

        <Route
            path="haul-pay/start"
            component={requireSelectors([hasNoUserType])(
                HaulPayMarketingNoMenu,
            )}
        />
        <Route
            path="haul-pay/join"
            component={requireSelectors([
                isCarrier,
                isBroker,
                isDispatcher,
                hasNoUserType,
            ])(FactoringSignUp)}
        />
        <Route
            path="beta/haul-pay"
            component={requireSelectors([
                isCarrier,
                isBroker,
                isDispatcher,
                hasNoUserType,
                isDispatchBroker,
            ])()}
        >
            <Route
                path="carriers"
                component={requireSelectors([isBroker])(
                    Factoring(BrokerInvitedCarriers),
                )}
            />
            <Route
                path="transaction"
                component={requireSelectors([isBroker])(
                    Factoring(BrokerTransaction),
                )}
            />
        </Route>
        <Route
            path="haul-pay"
            component={requireSelectors([
                isCarrier,
                isBroker,
                isDispatcher,
                hasNoUserType,
                isDispatchBroker,
            ])()}
        >
            <Route path="mywallet" component={requireBroker(BrokerWallet)} />
            <Route
                path="mycards"
                component={requireSelectors([isCarrier])(MyCards)}
            />
            <Route
                path="bulk_upload"
                component={requireSelectors([isCarrier])(CarrierBulkUpload)}
            />
            <Route
                path="bulk-uploads"
                component={requireBroker(FRBulkImport)}
            />
            <Route
                path="onboarding-success"
                component={requireSelectors([isBroker, isCarrier])(
                    StripeSuccessOnboarding,
                )}
            />
            <Route
                path="transaction"
                component={requireSelectors([isBroker])(
                    Factoring(DebtorTransactionContainer),
                )}
            >
                {transactionType}
                <Route path="history(/customer/:id)" />
            </Route>
            <Route
                path=":id/transaction"
                component={requireSelectors([isBroker])(
                    Factoring(FactoringPaymentEditBroker),
                )}
            />
            <Route
                path="addclients"
                component={Factoring(FactoringAddClients)}
            />
            <Route
                path="carriers"
                component={requireSelectors([isBroker])(
                    Factoring(FactoringCarriers),
                )}
            >
                <IndexRoute component={FactoringCarriersMyCarriers} />
                <Route path="invited" component={FactoringCarriersInvited} />
                <Route path="example" component={MyCarriersBrokers} />
            </Route>
            <Route path="getpaid" component={Factoring(FactoringGetPaid)}>
                <Route
                    path="request"
                    component={Factoring(FactoringRequestPayment)}
                />
                <Route
                    path="history(/customer/:id)"
                    component={Factoring(FactoringPayments)}
                />
                <Route
                    path="incomplete"
                    component={Factoring(FactoringPayments)}
                />
                <IndexRedirect to="request" />
            </Route>
            <Route
                path="getpaid/:id/assigned-loads"
                component={AssignedLoadPaymentView}
            />
            <Route
                path="getpaid/history/:id"
                component={Factoring(FactoringPayment)}
            />
            <Route path="getpaid/assigned-loads" component={AssignedLoads} />
            <Route path="profile" component={Factoring(FactoringARProfile)}>
                <Route
                    path="creditcheck"
                    component={Factoring(FactoringCreditCheck)}
                />
                <Route
                    path="client"
                    component={Factoring(FactoringUserProfile)}
                />
            </Route>
            <Route
                path={'transaction'}
                component={requireSelectors([isDispatchBroker])(
                    FactoringPaymentsAdmin,
                )}
            >
                <Route path={'special_pending'} />
                <Route path={'document_issue'} />
                <Route path={'incomplete'} />
                <Route path={'remote_approved'} />
                <Route path={'declined'} />
                <Route path={'approved'} />
                <Route path={'paid'} />
                <IndexRedirect to="special_pending" />
            </Route>
            <IndexRoute component={Factoring()} />
        </Route>

        <Route path="admin">
            <Route path="dashboard" component={requireAdmin(AdminDashboard)}>
                <IndexRoute component={requireAdmin(AdminDashboardStats2)} />
                <Route path="users" />
                <Route
                    path="clients"
                    component={requireAdmin(AdminDashboardClients)}
                />
                <Route
                    path="finance"
                    component={requireAdmin(AdminDashboardFinance)}
                />
                <Redirect from="*" to="/admin/dashboard" />
            </Route>
            <Route path="user">
                <Route
                    path="search"
                    component={requireSelectors(
                        [isAdmin, isSubAdmin, isFactoringAdmin, isAladdinAdmin],
                        '/404',
                    )()}
                >
                    <IndexRoute component={requireAdmin(UserSearch)} />
                    <Route path=":id" component={requireAdmin(UserEdit)} />
                </Route>
                <Route
                    path="list"
                    component={requireSelectors([
                        isAdmin,
                        isSubAdmin,
                        isFactoringAdmin,
                        isAladdinAdmin,
                    ])()}
                >
                    <Route path="myusers">
                        {['no_contact', 'first', 'second', 'third'].map(
                            (contact_status, i) => (
                                <Route
                                    key={i}
                                    path={contact_status}
                                    component={requireAdmin((props) => (
                                        <MyUserTypeList
                                            {...props}
                                            contact_status={contact_status}
                                        />
                                    ))}
                                />
                            ),
                        )}
                        <IndexRedirect to="no_contact" />
                    </Route>
                    <Route path="hp_interested">
                        {['no_contact', 'first', 'second', 'third'].map(
                            (contact_status, i) => (
                                <Route
                                    key={i}
                                    path={contact_status}
                                    component={requireAdmin((props) => (
                                        <MyUserHaulPayInterestedList
                                            {...props}
                                            contact_status={contact_status}
                                        />
                                    ))}
                                />
                            ),
                        )}
                        <IndexRedirect to="no_contact" />
                    </Route>
                    {[
                        'broker',
                        'carrier',
                        'shipper',
                        'dispatcher',
                        'broker_paid',
                        'carrier_paid',
                        'shipper_paid',
                        'dispatcher_paid',
                    ].map((user_type, i) => (
                        <Route key={i} path={user_type}>
                            {['no_contact', 'first', 'second', 'third'].map(
                                (contact_status, i) => (
                                    <Route
                                        key={i}
                                        path={contact_status}
                                        component={requireAdmin((props) => (
                                            <UserTypeList
                                                {...props}
                                                contact_status={contact_status}
                                                user_type={user_type}
                                            />
                                        ))}
                                    />
                                ),
                            )}
                            <IndexRedirect to="no_contact" />
                        </Route>
                    ))}
                </Route>
            </Route>
            <Route path="factoring">
                <Route path="client">
                    <Route
                        path="leads"
                        component={requireAdmin(FactoringLeads)}
                    >
                        <Route path="carrier" />
                        <Route path="broker" />
                        <Route path="dispatcher" />
                        <IndexRedirect to="client" />
                    </Route>
                    <Route
                        path="search"
                        component={requireAdmin(FactoringClientSearch)}
                    />
                </Route>
                <Route
                    path="client"
                    component={requireAdmin(FactoringClientLists)}
                >
                    <Route path="lead_in" />
                    <Route path="new" />
                    <Route path="broker_invited" />
                    <Route path="document_review" />
                    <Route path="document_issue" />
                    <Route path="pending" />
                    <Route path="contract_out" />
                    <Route path="ucc_hold" />
                    <Route path="approved" />
                    <Route path="declined" />
                    <Route path="bad_lead" />
                    <Route path="dead" />
                    <Route path="not_authorized" />
                    <IndexRedirect to="lead_in" />
                </Route>
                <Route
                    path="broker-invited"
                    component={requireAdmin(FactoringBrokerInvitedLists)}
                >
                    <Route path="pending" />
                    <Route path="approved" />
                    <Route path="declined" />
                    <IndexRedirect to="pending" />
                </Route>
                <Route
                    path="client/inactive"
                    component={requireAdmin(FactoringClientListInactive)}
                >
                    <Route path="full" />
                    <Route path="small" />
                </Route>
                <Route
                    path="client/:id"
                    component={requireAdmin(FactoringClient)}
                >
                    <IndexRoute component={requireAdmin(FactoringClientEdit)} />
                    <Route
                        path="payments"
                        component={requireAdmin(FactoringClientPayments)}
                    />
                    <Route
                        path="debtors"
                        component={requireAdmin(FactoringClientDebtors)}
                    />
                    <Route
                        path="debtoradd"
                        component={requireAdmin(FactoringClientDebtorSearch)}
                    />
                    <Route
                        path="paymentcreate"
                        component={requireAdmin(
                            FactoringClientPaymentCreateAdmin,
                        )}
                    />
                    <Route
                        path="brokerscarriers"
                        component={requireAdmin(FactoringClientBrokersCarriers)}
                    />
                </Route>
                <Route path="debtor">
                    <Route
                        path="create"
                        component={requireAdmin(FactoringDebtorCreate)}
                    />
                    <Route
                        path="search"
                        component={requireAdmin(FactoringDebtorSearch)}
                    />
                    <IndexRedirect to="create" />
                </Route>
                <Route
                    path="debtor"
                    component={requireAdmin(FactoringDebtorLists)}
                >
                    <Route path="pending" />
                    <Route path="verify_duplicate" />
                    <Route path="approved" />
                    <Route path="declined" />
                    <Route path="needs_review" />
                    <IndexRedirect to="pending" />
                </Route>
                <Route
                    path="debtor/:id"
                    component={requireAdmin(FactoringDebtor)}
                >
                    <IndexRoute component={requireAdmin(FactoringDebtorEdit)} />
                    <Route
                        path="invoices"
                        component={requireAdmin(FactoringDebtorPayments)}
                    />
                    <Route
                        path="payments"
                        component={requireAdmin(FactoringInvoicesPayments)}
                    />
                    <Route
                        path="clients"
                        component={requireAdmin(FactoringDebtorClients)}
                    />
                </Route>
                <Route path="transaction">
                    <Route
                        path="search"
                        component={requireSelectors(
                            [
                                isAdmin,
                                isSubAdmin,
                                isFactoringAdmin,
                                isFactoringSubAdmin,
                                isFactoringRemoteAdmin,
                                isFactoringRemoteCoordinator,
                                isAladdinAdmin,
                                isDispatchBroker,
                            ],
                            '/404',
                        )(FactoringPaymentsSearch)}
                    />
                </Route>
                <Route
                    path="transaction"
                    component={requireAdmin(FactoringPaymentsAdmin)}
                >
                    <Route path="new" />
                    <Route path="fuel_advance" />
                    <Route path="pending" />
                    <Route path="pending_originals" />
                    <Route path="approved" />
                    <Route path="remote_approved" />
                    <Route path="declined" />
                    <Route path="paid" />
                    <Route path="document_issue" />
                    <Route path="document_review" />
                    <Route path="haulpay_review" />
                    <Route path="incomplete" />
                    <Route path="new_noa" />
                    <Route path="pending_noa" />
                    <Route path="special_pending" />
                    <Route path="draft" />
                    <IndexRedirect to="pending" />
                </Route>
                <Route path="transaction/:id">
                    <IndexRoute
                        component={requireSelectors([
                            isAdmin,
                            isSubAdmin,
                            isFactoringAdmin,
                            isFactoringSubAdmin,
                            isFactoringRemoteAdmin,
                            isFactoringRemoteCoordinator,
                            isAladdinAdmin,
                            isDispatchBroker,
                        ])(FactoringPaymentsAdminEdit)}
                    />
                    <Route
                        path="lineitems"
                        component={requireAdmin(FactoringLineItemCreate)}
                    />
                </Route>
            </Route>
            <Route path="dispatch/:id" component={requireAdmin(Dispatch)}>
                <Route path="search" component={requireAdmin(DispatchSearch)} />
                <Route path="list" component={requireAdmin(DispatchList)} />
                <Route
                    path="card"
                    component={requireAdmin(DispatchUpdateCard)}
                />
                <Route
                    path="load/:load"
                    component={requireAdmin(({ params: { load } }) => (
                        <LoadDetails routeParams={{ id: load }} />
                    ))}
                />
                <Route
                    path="onboarding"
                    component={requireAdmin(DispatchOnboarding)}
                />
                <IndexRedirect to="search" />
            </Route>
            <Route path="finances">
                <Route path="payments">
                    <IndexRoute component={requireAdmin(FinancesPayments)} />
                    <Route
                        path="search"
                        component={requireAdmin(FinancesPaymentsDebtorSearch)}
                    />
                    <Route
                        path=":id"
                        component={requireAdmin(FinancesPaymentEdit)}
                    />
                </Route>
                <Route path="purchases">
                    <IndexRoute component={requireAdmin(FinancesPurchases)} />
                    <Route
                        path=":id"
                        component={requireAdmin(FinancesPurchaseEdit)}
                    />
                </Route>
                <Route path="lineitems/categories">
                    <IndexRoute component={requireAdmin(LineItemCategories)} />
                </Route>
            </Route>
            <Route path="settings" component={requireAdmin(Settings)}>
                <Route
                    path="account"
                    component={requireAdmin(SettingsAccount)}
                />
                <IndexRedirect to="account" />
            </Route>
            <Route path="funding_requests">
                {/*<Route path=':frid/purchases/:id' component={FactoringPurchaseTransactionEditForm} />*/}
                {/*<Route path=':frid/payments/:id' component={FactoringPaymentTransactionEditForm} />*/}
            </Route>
        </Route>
        <Route path="saved">
            <Route path="loads" component={SavedLoads} />
            <Route path="trucks" component={SavedTrucks} />
            <Route path="posts" component={SavedPosts}>
                <Route path="loads" component={SavedLoads} />
                <Route path="trucks" component={SavedTrucks} />
            </Route>
        </Route>

        <Route path="/404" component={Error404} />
        <Redirect from="*" to="/404" />
    </Route>
);

export default routes;
