import React from 'react';
import { UploadProvider } from './UploadContext';

const withUploadProvider = (WrappedComponent) => (props) => (
    <UploadProvider>
        <WrappedComponent {...props} />
    </UploadProvider>
);

export default withUploadProvider;
