import React, { createContext, useContext, useState, useEffect } from 'react';

export const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const [uploadedEvent, setUploadedEvent] = useState(false);

    const triggerUploadEvent = () => {
        console.log('Triggering upload event...');
        setUploadedEvent(true);
        setTimeout(() => {
            console.log('Resetting upload event...');
            setUploadedEvent(false);
        }, 1000);
    };

    useEffect(() => {
        console.log('Context value updated:', uploadedEvent);
    }, [uploadedEvent]);

    return (
        <UploadContext.Provider value={{ uploadedEvent, triggerUploadEvent }}>
            {children}
        </UploadContext.Provider>
    );
};

export const useUploadContext = () => {
    const context = useContext(UploadContext);
    if (!context) {
        throw new Error(
            'useUploadContext must be used within an UploadProvider',
        );
    }
    return context;
};
