import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import Header from 'components/pure/Header';
import ModalFooter from 'components/pure/ModalFooter';
import MaterialIcon from 'components/pure/MaterialIcon';
import PureUploadFile from 'components/pure/PureUploadFile';
import ImageCropper from 'components/pure/ImageCropper';
import { closeModal, openModal } from '../../../../actions/ui';
import { clearFiles } from '../../../../actions/upload';

class UploadAttachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cropping: false,
            onCrop: () => ({}),
            onCropCancel: () => ({}),
        };
        this.setState = this.setState.bind(this);
    }

    render() {
        const { dispatch, id } = this.props;
        const { cropping, onCrop, onCropCancel } = this.state;
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flex: 0.5,
                        justifyContent: 'flex-end',
                    }}
                >
                    <button
                        className="btn btn-orange"
                        style={{
                            backgroundColor: '#2196f3',
                            fontSize: '14px',
                            color: 'white',
                            border: 0,
                        }}
                        onClick={() =>
                            dispatch(
                                openModal('EmptyModal', {
                                    children: (
                                        <div>
                                            <Header>Upload Attachments</Header>
                                            <ModalBody>
                                                <PureUploadFile
                                                    requestId={id}
                                                    dropZone
                                                    multiple={true}
                                                    setCropValues={
                                                        this.setState
                                                    }
                                                />
                                            </ModalBody>
                                            <ModalFooter>
                                                <button
                                                    className="btn btn-orange"
                                                    onClick={() => {
                                                        dispatch(clearFiles());
                                                        dispatch(closeModal());
                                                    }}
                                                >
                                                    Finish
                                                </button>
                                            </ModalFooter>
                                        </div>
                                    ),
                                    bsSize: 'medium',
                                }),
                            )
                        }
                        type="button"
                    >
                        Upload Documents{' '}
                        <MaterialIcon className="mdi-24px" name="backup" />
                    </button>
                </div>
                {cropping ? (
                    <ImageCropper
                        file={cropping}
                        onCrop={onCrop}
                        onCancel={onCropCancel}
                        convertAnyway={false}
                    />
                ) : null}
            </div>
        );
    }
}

export default UploadAttachments;
