import React from 'react';
import { connect } from 'react-redux-v5';
import { fetchPaymentRequestAttachments } from 'actions/resource/factoringpayment';
import { UploadContext } from '../context/UploadContext';

const autoUpdatesAttachments = () => (WrappedComponent) => {
    class AutoUpdatesAttachments extends React.Component {
        intervalRef = null;
        timeoutRef = null;

        fetchAttachments = async () => {
            const { fetchPaymentRequestAttachments, params } = this.props;
            const { id } = params || {};
            try {
                await fetchPaymentRequestAttachments(id);
            } catch (error) {
                console.error('Error fetching attachments:', error);
            }
        };

        componentDidMount() {
            this.fetchAttachments();
        }

        componentDidUpdate(prevProps) {
            const { uploadedEvent } = this.props;
            if (uploadedEvent && uploadedEvent !== prevProps.uploadedEvent) {
                this.startAutoUpdate();
            }
        }

        componentWillUnmount() {
            clearInterval(this.intervalRef);
            clearTimeout(this.timeoutRef);
        }

        startAutoUpdate = async () => {
            // Start polling every 10 seconds
            this.intervalRef = setInterval(this.fetchAttachments, 10000);

            // Stop polling after 1 minute
            this.timeoutRef = setTimeout(() => {
                clearInterval(this.intervalRef);
            }, 60000);
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = {
        fetchPaymentRequestAttachments,
    };

    return connect(
        null,
        mapDispatchToProps,
    )((props) => (
        <UploadContext.Consumer>
            {({ uploadedEvent, triggerUploadEvent }) => (
                <AutoUpdatesAttachments
                    {...props}
                    uploadedEvent={uploadedEvent}
                    triggerUploadEvent={triggerUploadEvent}
                />
            )}
        </UploadContext.Consumer>
    ));
};

export default autoUpdatesAttachments;
